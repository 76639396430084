import React, { useEffect } from 'react'
import {ContentContainer} from 'common/content-container/content-container'
import {tr} from 'translations/translate'
import {TEXT_SUBMIT} from 'translations/keys'
import LayoutDefault from 'common/layout-default/layout-default'
import {ContentSection} from 'common/content-section/content-section'
import {useDispatch, useSelector} from 'react-redux'
import {isSubmitting, isValid, submit} from 'redux-form'
import {FireLogo} from 'fire/fire-logo/fire-logo'
import {FireFormPageProps} from 'fire/types/fire-form-page-props.type'
import {MappingAuditForm} from "./mapping-case-form";
import {OtherFormName} from "../../enums/form-name.enum";
import {CircularProgress, Grid} from "@material-ui/core";
import {ButtonContained} from "../../../common/button-contained/button-contained";
import {PostalAuditInfo} from './mapping-case-info'
import {useParams} from "react-router-dom";
import {actionSaveMappingResponse} from "../../other-actions";
import {convertObjectToFormData} from "../../../utils";
import { actionStoreTenantId } from 'app/app-actions'

export const MappingCase: React.FC<FireFormPageProps> = props => {
    const dispatch = useDispatch()
    const isFormSubmitting = useSelector(isSubmitting(OtherFormName.PostalAudit))
    const isFormValid = useSelector(isValid(OtherFormName.PostalAudit))
    const {tenantId, auditId, pin, targetId}: any = useParams()
    const audit = useSelector((state: any) => state.other.postalAudit)

    useEffect(() => {
        dispatch(actionStoreTenantId(tenantId))
    }, [tenantId])

    const handleSubmit = (formValue: any) => {

        const formData =  {...formValue, pinCode:Number(pin), tenantId, auditId, targetId }
        dispatch(actionSaveMappingResponse(convertObjectToFormData(formData, {})))
    }

    return (
        <LayoutDefault logo={<FireLogo staticId={tenantId} />}>
            <ContentContainer>

                <PostalAuditInfo/>
                <ContentSection>
                    <MappingAuditForm onSubmit={handleSubmit} formBuilderData={audit?.formBuilderData} />
                </ContentSection>

                <ContentSection>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <ButtonContained
                                    color="secondary"
                                    onClick={() => dispatch(submit(OtherFormName.PostalAudit))}
                                    disabled={!isFormValid || isFormSubmitting}
                                >
                                    {isFormSubmitting && (
                                        <CircularProgress
                                            size="16px"
                                            style={{position: 'absolute', left: 0, right: 0, margin: 'auto'}}
                                        />
                                    )}
                                    <span style={isFormSubmitting ? {opacity: 0.5} : {}}>{tr(TEXT_SUBMIT)}</span>
                                </ButtonContained>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentSection>
            </ContentContainer>
        </LayoutDefault>
    )
}
