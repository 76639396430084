import * as keys from '../keys'
import {AuditStatus, AuditType} from "../../common/audit/audit";

export const nb: { [index: string]: string } = {}

nb[keys.TEXT_SUBMIT] = 'Send inn'
nb[keys.TEXT_COMMENT] = 'Kommentar'
nb[keys.TEXT_COMMENT_DESCRIPTION] = 'Kommentar / beskrivelse'
nb[keys.TEXT_DESCRIPTION] = 'Beskrivelse'
nb[keys.TEXT_DESCRIBE_REASON_FOR_APPLICATION] = 'Beskriv årsak til søknad'
nb[keys.TEXT_UPLOAD] = 'Last opp'
nb[keys.TEXT_UPLOAD_DOCUMENTATION] = 'Last opp dokumentasjon'
nb[keys.TEXT_NEXT] = 'Neste'
nb[keys.TEXT_BACK] = 'Tilbake'
nb[keys.TEXT_CANCEL] = 'Avbryt'
nb[keys.TEXT_YES] = 'Ja'
nb[keys.TEXT_NO] = 'Nei'
nb[keys.TEXT_NAME] = 'Navn'
nb[keys.TEXT_BUSINESS] = 'Bedrift'
nb[keys.TEXT_COMPANY] = 'Firma'
nb[keys.TEXT_EXECUTIVE_COMPANY] = 'Utførende selskap'
nb[keys.TEXT_ADDRESS] = 'Adresse'
nb[keys.TEXT_ZIP_CODE] = 'Post kode'
nb[keys.TEXT_DATE_FROM] = 'Dato fra'
nb[keys.TEXT_DATE_TO] = 'Dato til'
nb[keys.TEXT_DATE_TIME] = 'Tidspunkt'
nb[keys.TEXT_EMAIL] = 'E-post'
nb[keys.TEXT_NO_RESULTS] = 'Ingen resultater'
nb[keys.TEXT_LOADING] = 'Lasting'
nb[keys.TEXT_OWNER] = 'Eieren'
nb[keys.TEXT_REMOVE] = 'Fjerne'


//#region TAX
nb[keys.TEXT_RESIDENTIAL_PROPERTY] = 'Boligeiendom'
nb[keys.TEXT_LEISURE_PROPERTY] = 'Fritidseiendom'
nb[keys.TEXT_FARMING_FORESTRY_WITH_HOUSING] = 'Gårdsbruk/skogbruk med bolig'
nb[keys.TEXT_FARM_USE_FORESTRY_WITH_LEISURE] = 'Gårdsbruk/skogbruk med fritidsb'
nb[keys.TEXT_FARMS_OR_FORESTRY] = 'Gårdsbruk eller skogbruk'
nb[keys.TEXT_NOT_SELECTED] = 'Ikke valgt'
nb[keys.TEXT_COMBINED_BUILT_PLOT] = 'Kombinert bebygd tomt'
nb[keys.TEXT_COMMERCIAL] = 'Næringseiendom'
nb[keys.TEXT_UNDEVELOPED_PLOT_HOUSING] = 'Ubebygd tomt, bolig'
nb[keys.TEXT_UNDEVELOPED_PLOT_LEISURE] = 'Ubebygd tomt, fritid'
nb[keys.TEXT_UNDEVELOPED_PLOT_INDUSTRY] = 'Ubebygd tomt, næring'
nb[keys.TEXT_UNDEVELOPED_PLOT] = 'Ubebygd tomt'
nb[keys.TEXT_CORRECTION_OF_FACTS_AND_SUBMISSION_OF_APPEAL_AGAINST_PROPERTY_TAX] =
    'Retting av fakta og insending av klage ved eiendomskatt'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_1] = 'Tomtearealet er ikke korrekt'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_2] = 'Tomtetype er ikke korrekt'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_3] = 'Tomten og bygningene har fått urimelig god beliggenhet'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_4] = 'Bygning har fått urimelig høy standard'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_5] = 'Feil areal av bygning'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_6] = 'Bilder er fra feil eiendom'
nb[keys.TEXT_TAX_COMPLAINT_CAUSE_7] = 'Andre rettelser/klager'
nb[keys.TEXT_REGISTERED_SITE_USE] = 'Registrert tomtebruk'
nb[keys.TEXT_PROPER_SITE_USE] = 'Korrekt tomtebruk'
nb[keys.TEXT_REGISTERED_AREA] = 'Registrert areal'
nb[keys.TEXT_CORRECT_AREA] = 'Korrekt areal'
nb[keys.TEXT_CHOOSE_BUILDING] = 'Velg bygning'
nb[keys.TEXT_ADD_BUILDING] = 'Legg til bygning'
nb[keys.TEXT_LOOK_AT_MAP] = 'Se på kart'
nb[keys.TEXT_SELECT_PHOTOS_THAT_ARE_FROM_WRONG_PROPERTY] = 'Velg bilder som er av feil eiendom'
nb[keys.TEXT_FLOOR] = 'Etasje'
nb[keys.TEXT_USE] = 'Bruk'
nb[keys.TEXT_AREA] = 'Areal'
nb[keys.TEXT_REQUIRED] = 'Nødvendig'
nb[keys.TEXT_VALID_EMAIL] = 'Skriv inn gyldig e-post'
nb[keys.TEXT_VALID_PHONE] = 'Skriv inn gyldig norsk telefonnummer'
//#endregion

//#region FIRE
nb[keys.TEXT_FIREPLACE_INSTALLATION_NOTICE] = 'Melding om installasjon av ildsted'
nb[keys.TEXT_FIREPLACE_REMOVAL_NOTICE] = 'Melding om fjerning av ildsted'
nb[keys.TEXT_APPLICATION_FOR_SCRAP_VALUE] = 'Søknad om vrakpant'
nb[keys.TEXT_NOTICE_OF_REHABILITATION_NEW_CHIMNEY] = 'Melding om rehabilitering av skorstein/ny skorstein'
nb[keys.TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION] = 'Søknad om dispensasjon fra fjerning av oljetank'
nb[keys.TEXT_NOTICE_OF_TANK_REMOVAL] = 'Melding om fjerning av oljetank'
nb[keys.TEXT_NOTICE_OF_ACCOMMODATION] = 'Melding om overnatting'
nb[keys.TEXT_NOTICE_OF_EVENT] = 'Melde arrangement til brannvesenet'
nb[keys.TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS] = 'Søke om bruk av fyrverkeri eller pyrotekniske effekter'
nb[keys.TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS] = 'Søke om salg av fyrverkeri eller pyrotekniske effekter'
nb[keys.TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER] = 'Søknad om oppbevaring av røyksvakt krutt'
nb[keys.TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT] = 'Bestille kontroll på nye brannhydranter og -ventiler'
nb[keys.TEXT_INSTALLATION_LOCATION] = 'Installasjonssted'
nb[keys.TEXT_REMOVAL_LOCATION] = 'Fjerningssted'
nb[keys.TEXT_THE_NEW_FIREPLACE] = 'Det nye ildstedet'
nb[keys.TEXT_THE_INSTALLATION] = 'Installasjonen'
nb[keys.TEXT_PRODUCT_NAME] = 'Produktnavn'
nb[keys.TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE] = 'Produktnavn er fabrikat og modell til ildstedet'
nb[keys.TEXT_FUEL_TYPE] = 'Brenselstype'
nb[keys.TEXT_WOOD] = 'Ved'
nb[keys.TEXT_INSTALLATION_DATE] = 'Dato installert'
nb[keys.TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE] = 'Hvilke adresse gjelder installasjone? '
nb[keys.TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED] = 'Adresse hvor ildsted fjernes'
nb[keys.TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH] = 'Hvilke røykløp er ildstedet knyttet til'
nb[keys.TEXT_ASSOCIATED_FIREPLACE] = 'Tilknyttet ildsted'
nb[keys.TEXT_OTHER_CHIMNEY_UNCERTAIN] = 'Annet røykløp / Usikker'
nb[keys.TEXT_ARE_FIREPLACES_REMOVED] = 'Fjernes ildsted?'
nb[keys.TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF] = 'Har du installert ildstedet selv?'
nb[keys.TEXT_NEW_FIREPLACE_INSTALLATION_2] =
    'Er ildstedet godkjent  for bruk i Norge, og installert etter gjeldende regler?'
nb[keys.TEXT_CHIMNEY] = 'Røykløp'
nb[keys.TEXT_FIREPLACE_NAME] = 'Fjernes ildstedet'
nb[keys.TEXT_CHIMNEY_ASSOCIATION_TEXT] = 'Beskriv hvilket røykløp ildstedet er knyttet til'
nb[keys.TEXT_LIQUID_AND_GAS] = 'Flytende og gass'

nb[keys.TEXT_WE_HAVE_FOLLOWING_FIREPLACES] = 'Vi har følgende ildsteder registrert på din adresse'
nb[keys.TEXT_WHICH_FIREPLACE_IS_REMOVED] = 'Hvilken ildsted er fjernet?'
nb[keys.TEXT_ASSOCIATED_CHIMNEY] = 'Tilknyttet røykløp'
nb[keys.TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE] = 'Hvilket ildsted er fjernet ?'
nb[keys.TEXT_NEW_HEATING_SOURCE] = 'Ny oppvarmingskilde'
nb[keys.TEXT_DOCUMENTATION] = 'Dokumentasjon'
nb[keys.TEXT_SELECT_THE_HEATING_SOURCE] = 'Velg oppvarmingkilde'

nb[keys.TEXT_CLEAN_BURNING_FIREPLACE] = 'Rentbrennende ildsted'
nb[keys.TEXT_HEAT_PUMP] = 'Varmepumpe'
nb[keys.TEXT_SOLAR] = 'Solcelle'
nb[keys.TEXT_DISTRICT_HEATING] = 'Fjernvarme'

nb[keys.TEXT_INSTALLER] = 'Installatør'
nb[keys.TEXT_MOBILE_NUMBER] = 'Mobilnummer'
nb[keys.TEXT_INSTALLERS_ADDRESS] = 'Installatørens adresse'
nb[keys.TEXT_NORWEGIAN_ADDRESS] = 'Norsk adresse'
nb[keys.TEXT_FOREIGN_ADDRESS] = 'Utenlandsk adresse'

nb[keys.TEXT_PROOF_OF_REFUND] = 'Pantebevis'
nb[keys.TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR] =
    'Sjekkliste og kontrollerklæring av kvalifisert kontrollør'

nb[keys.TEXT_LOCATION] = 'Lokasjon'
nb[keys.TEXT_ABOUT_EVENT] = 'Om arrangementet'
nb[keys.TEXT_RISK_ASSESSMENT] = 'Risikovurdering'
nb[
    keys.TEXT_EVENT_1
] = `Ved store arrangemet/ arrangement med økt risiko er det nødvendig å sette inn tiltak av forebyggende og beredskapsmessig art.`
nb[
    keys.TEXT_EVENT_1_2
] = `Dersom arrangementet holdes et sted som normalt ikke benyttes til denne type arrangement må skjema sendes inn minst  4 uker før arrangementet.`
// eslint-disable-next-line no-template-curly-in-string
nb[keys.TEXT_EVENT_2] = 'Innmelder vil være kontaktperson mot ${tenantName} i forbindelse med arrangementet'
nb[keys.TEXT_ORGANIZER] = 'Arrangør'
nb[keys.TEXT_COMPANY_NAME] = 'Selskapsnavn'
nb[keys.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING] = 'Søk etter adresse eller navn på bygning'
nb[keys.TEXT_DESCRIBE_LOCATION] = 'Beskriv lokasjon'
nb[keys.TEXT_DESCRIBE_ARRANGEMENT] = 'Beskriv arrangement/ type arrangement'
nb[keys.TEXT_IS_PYROTECHNICS_WILL_BE_USED] = 'Skal det benyttes scenerøyk/ pyrotekniske effekter?'
nb[keys.TEXT_IS_FIRE_SERVICE_SAFEGUARDED] = 'Er fremkommelighet til brannvesen ivaretatt?'
nb[keys.TEXT_EVENT_3] =
    // eslint-disable-next-line no-template-curly-in-string
    'Utkoblinger av brannalarmanlegget er IKKE tillatt. Dersom det skal benyttes pyrotekniske effekter må det søkes om tillatelse til ${tenantName}.'
nb[keys.TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS] = 'Søknad om bruk av fyrverkeri/pyrotekniske effekter'
nb[keys.TEXT_RISK_ASSESSMENT_FOR_EVENT] = 'Risikovurdering for arrangementet'

nb[keys.TEXT_PYROTECHNICS_USE_STEP2] = 'Sted tid og dokumentasjon'
// eslint-disable-next-line no-template-curly-in-string
nb[keys.TEXT_PYROTECHNICS_USE_FORM1_DESCRIPTION] = 'Søker vil være kontaktperson mot ${tenantName} i forbindelse med denne søknaden. Denne personen må også kunne fremlegge all etterspurt dokumentasjon på at forskriftens krav er oppfylt.'
nb[keys.TEXT_PYROTECHNICS_USE_IS_SENDER_RESPONSIBLE] = 'Er innmelder ansvarshavende?'
nb[keys.TEXT_PYROTECHNICS_USE_IS_TYPE_FIREWORKS] = 'Hvilke type fyrverkeri søkes det om tilltatelse til å bruke?'
nb[keys.TEXT_PYROTECHNICS_USE_IS_CONSUMER_TYPE_FIREWORKS] = 'Forbrukertilgjengelig fyrverkeri kategori II og III'
nb[keys.TEXT_PYROTECHNICS_USE_IS_PROFESSIONAL_TYPE_FIREWORKS] = 'Profesjonelt fyrverkeri'
nb[keys.TEXT_PYROTECHNICS_USE_IS_STAGE_TYPE_FIREWORKS] = 'Pyrotekniske sceneefekter'
nb[keys.TEXT_PYROTECHNICS_USE_CERTIFICATE_INFO] = 'Forskriftens kapittel 13 stiller krav til kompetansebevis (sertifikat ustedet av brannvernforeningen), samt krav til at det foreligger skriftlige planer og rutiner for å ivareta sikkerheten i forbindelse med avfyringen.'
nb[keys.TEXT_PYROTECHNICS_USE_CERTIFICATE_NUMBER] = 'Sertifikatnummer'
nb[keys.TEXT_PYROTECHNICS_USE_HAS_WRITTEN_SAFETY_INSTRUCTIONS] = 'Foreligger det skriftlige planer og instrukser for å ivareta all sikkerhet i forbindelse med bruken?'
nb[keys.TEXT_PYROTECHNICS_DATE] = 'Når skal fyrverkeri benyttes'
nb[keys.TEXT_PYROTECHNICS_LOCATION_DESCRIPTION] = 'Kommentar til lokasjon'
nb[keys.TEXT_PYROTECHNICS_DESCRIPTION] = 'I hvilke anledning skal fyrverkeri benyttes'
nb[keys.TEXT_PYROTECHNICS_ATTACHMENTS] = 'Legg ved skriftelige planer og instrukser'
nb[keys.TEXT_PYROTECHNICS_ATTACHMENTS_BUTTON] = 'LEGG TIL DOKUMENT'


nb[keys.TEXT_ACCOMMODATION] = 'Overnattingssted'
nb[keys.TEXT_ABOUT_ACCOMMODATION] = 'Om overnattingen'
nb[keys.TEXT_FIRE_SAFETY] = 'Brannsikkerhet'
nb[keys.TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING] = 'Er ansvarshavende en annen person en innmelder?'
nb[keys.TEXT_NUMBER_OF_PEOPLE] = 'Antall personer'
nb[keys.TEXT_ACCOMMODATION_1] =
    'Beskriv overnatting: Bakgrunn for overnatting, arealer (rombenevnelse og etasje) som er i bruk'
nb[keys.TEXT_ACCOMMODATION_2] =
    'Foreligger det skriftlige planer og instrukser for å ivareta all sikkerhet i forbindelse med overnattingen?'

nb[keys.TEXT_ACCOMMODATION_CONFIRM] = 'Jeg bekrefter at følgende vil være på plass før overnattingen:'
nb[keys.TEXT_OWNER_CONSENT] = 'Eier av bygget er informert og har gitt samtykke'
nb[keys.TEXT_RESPONSIBLE_CONSENT] = 'Ansvarspersoner kjenner til aktiviteten og har gitt samtykke'
nb[keys.TEXT_RESPONSIBLE_CAN_FIREGUARD] = 'Ansvarshavende er i stand til å ivareta brannsikkerheten ved overnattingen'
nb[keys.TEXT_AWAKE_NIGHT_WATCH] = 'Våken nattevakt'
nb[keys.TEXT_SUFFICIENT_NUMBER_OF_GUARDS] = 'Tilstrekkelig antall vakter'
nb[keys.TEXT_NO_FLAMMABLE_ACTIVITY] = 'Det må ikke forekomme brannfarlig aktivitet'
nb[keys.TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES] = 'Alle må kjenne rømningsveier før overnatting'
nb[keys.TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE] = 'Rømningsveier må være ryddig og lett tilgjengelig'
nb[keys.TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING] =
    'Brannvarslingsanlegg må være kontrollert og fungere. (Om det er røykvarslere må disse sjekkes)'
nb[keys.TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING] = 'Slukkeutstyr må være kontrollert og fungere'
nb[keys.TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING] = 'Ledesystem må være kontrollert og fungere'
nb[keys.TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING] =
    'Sprinkleranlegg må være kontrollert og fungere om dette er montert'
nb[keys.TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART] = 'Overnatting begrenses til en avtalt del av bygget'

nb[keys.TEXT_APPLICATION_RECEIVED] = 'Søknaden er mottatt'
nb[keys.TEXT_MESSAGE_RECEIVED] = 'Meldingen er mottatt'
nb[keys.TEXT_FIREPLACE_REMOVED] = 'Ildsted er fjernet'
nb[keys.TEXT_APPLY_FOR_PYROTECHNICS] = 'Her kan du søke om bruk av pyroteknikk'
nb[keys.TEXT_FIREPLACE_INSTALL_SUCCESS_1] =
    'Opplysningene om nytt ildsted vil oppdateres så snart meldingen er behandlet.'
nb[keys.TEXT_ACCOMMODATION_SUCCESS_1] =
    'Dersom vi trenger mer informasjon for å ivareta brannsikkerhet under overnattingen vil vi ta kontakt for videre oppfølging'
nb[keys.TEXT_EVENT_SUCCESS_1] =
    'Dersom vi trenger mer informasjon for å ivareta brannsikkerhet under arrangementet vil vi ta kontakt for videre oppfølging'
nb[keys.TEXT_SUCCESS_1] = 'Du vil få tilbakemelding på søknaden så snart den er behandlet.'
nb[keys.TEXT_SUCCESS_2] = 'Dersom vi trenger mer informasjon for å behandle den, vil vi ta kontakt.'
nb[keys.TEXT_THANK_YOU_FOR_USING_OUR_SERVICES] = 'Takk for at du bruker våre digitale tjenester'

nb[keys.TEXT_WHAT_IS_THE_ADDRESS] = 'Hvilke adresse gjelder det? '
nb[keys.TEXT_ACCOUNT_NUMBER] = 'Kontonummer'
nb[keys.TEXT_PAYOUT_ACCOUNT] = 'Konto for utbetaling'

nb[keys.TEXT_TYPE] = 'Type'
nb[keys.TEXT_CONTENTS] = 'Innhold'
nb[keys.TEXT_VOLUME] = 'Volum'

nb[keys.TEXT_TANK_EXEMPTION_1] =
    'Bare oljetanker der det er uforholdsmessig komplisert og/eller uforholdsmessig kostbart å fjerne de kan søke om dispensasjon. Det er derfor viktig at du doumenterer årsaken til søknaden.'
nb[keys.TEXT_TANK_EXEMPTION_2] = 'Dokumentasjon av årsak. F.eks bilder av området som er påvirket'
nb[keys.TEXT_TANK_EXEMPTION_REASON] = 'Årsak til søknad om dispensasjon fra fjerning av oljetank'

nb[keys.TEXT_TANK_DID_LEAK] =
    'Ble det ved oppgraving registrert spor av olje, lukt eller annet som tyder på lekkasje fra tanken?'
nb[keys.TEXT_TANK_PIPES_REMOVED] =
    'Alle rør tilknyttet tanken er fjernet eller gjort ubrukelige slik at utilsiktet fylling ikke kan skje.'
nb[keys.TEXT_TANK_REMOVAL_DOCUMENTATION] = 'Dokumentasjon av fjerning av oljetank. F.eks bilder.'
nb[keys.TEXT_NOTIFICATION_OF_TANK_REMEDIATION] = 'Melding om sanering av oljetank'
nb[keys.TEXT_TANK_REMEDIATION_DESCRIPTION] = 'Beskrivelse av sanering'
nb[keys.TEXT_TANK_REMEDIATION_DOCUMENTATION] = 'Dokumentasjon av sanering. F.eks bilder av området som er påvirket'

nb[keys.TEXT_NO_REGISTERED_TANKS] = 'Ingen tank registrert på denne eiendommen'

nb[keys.TEXT_REQUIRED_INFORMATION] = 'Påkrevd informasjon'
nb[keys.TEXT_CONTACT_INFORMATION] = 'Kontaktinformasjon'
nb[keys.TEXT_VA_PLANT] = 'VA anlegget'
nb[keys.TEXT_VA_IS_CONFIRMED] = 'Det bekreftes at'
nb[keys.TEXT_VA_LID_AVAILABLE] = 'Kapsellokket er tilgjengelig (ikke dekket av jord, grus m.m)'
nb[keys.TEXT_VA_CORRECT_COVER_FITTED] = 'Det er montert riktig kapsellokk (oval åpning, ikke spetthull)'
nb[keys.TEXT_VA_VALVE_PROTECTION_REMOVED] =
    'Brannventilsikring er fjernet fra ventilen, eventuelt at det er montert ventilstang'
nb[keys.TEXT_VA_WATER_ON_VALVE] = 'Det er vann på ventilen'
nb[keys.TEXT_VA_DISTANCE] = 'Avstanden mellom brannkule og kapsellokk er mellom 70-90 cm'
nb[keys.TEXT_VA_WIRED_CAP] = 'Beskyttelseshette er montert med vaier'
nb[keys.TEXT_ATTACH_MAP_SECTION] = 'Legg ved kartutsnitt'
nb[keys.TEXT_MAP_SECTIONS] = 'Kartutsnitt'
nb[keys.TEXT_AS_BUILT_DRAWINGS] = 'AS BUILT tegninger'
nb[keys.TEXT_DRAWINGS] = 'Tegninger'
nb[keys.TEXT_VA_OUTLET_NAME] = 'Navn på slukkevannsuttak'

nb[keys.TEXT_CHOOSE_ORGANIZATION] = 'Velg organisasjon'
nb[keys.TEXT_COMPANY_ORG_NUMBER] = 'Bedriftens organisasjonsnummer'
nb[keys.TEXT_CONTACT_EMAIL] = 'Kontakt e-post'
nb[keys.TEXT_CONTACT_PHONE] = 'Kontakt telefonnummer'
nb[keys.TEXT_INVOICE_ADDRESS_IF_DIFFERENT] = 'Faktura adresse, hvis forskjellig fra postadresse'
nb[keys.TEXT_EVENTUAL_NOTES_INVOICE] = 'Eventuelle merknader faktura'
nb[keys.TEXT_PYROTECHNICS_PLACE] = 'Sted for avfyring'

nb[keys.TEXT_RESPONSIBLE] = 'Ansvarshavende'
nb[keys.TEXT_PLACE_OF_SALE] = 'Utsalgssted'
nb[keys.TEXT_HMS_TRAINING] = 'HMS erklæring'
nb[keys.TEXT_CONTACT_PERSON] = 'Kontaktperson'
nb[keys.TEXT_SEARCH_BY_ORG_ADDRESS_OR_NAME] = 'Søk etter organisasjonsnummer, adresse eller navn på virksomhet'
nb[keys.TEXT_PYRO_SALE_1] = 'Er ansvarshavende en annen person enn innmelder?'
nb[keys.TEXT_PYRO_SALE_2] =
    'Virksomheten skal ha ansatt en ansvarshavende, og eventuell stedfortreder, med gyldig kompetansebevis.'
nb[keys.TEXT_PYRO_SALE_3] =
    'Det bekreftes herved at det vil være ansatt en ansvarshavende person i virksomheten all den tid virksomheten håndterer fyrverkeri (fra første vare ankommer, til eventuelle returvarer blir levert inn ved utgangen av januar)'
nb[keys.TEXT_PYRO_SALE_PLACE] = 'Hvor skal fyrverkeri utsalget foregå'
nb[keys.TEXT_PYRO_USE_PLACE] = 'Hvor skal fyrverkeri bruk foregå'

nb[keys.TEXT_PYRO_SALE_INDOORS] = 'Innendørs'
nb[keys.TEXT_PYRO_SALE_OUTDOORS] = 'Utendørs'
nb[keys.TEXT_PYRO_SALE_4] =
    'Dersom salget skal foregå INNENDØRS kreves en  brannteknisk skisse som tydelig viser plassering av salgsdesk, evakuering og at sikkerhetsavstander er i henhold til regelverkets krav.  Se eksempel her.'
nb[keys.TEXT_PYRO_SALE_5] =
    'Dersom salget skal foregå UTENDØRS kreves en skisse som tydelig viser plassering av utsalgssted, og at sikkerhetsavstander er i samssvar med regelverkets krav. Se eksempel her.'
nb[keys.TEXT_OUTLET_LOCATION_SKETCH] = 'Skisse for plassering av utsalgsted'
nb[keys.TEXT_SKETCH] = 'Skisse'
nb[keys.TEXT_PYRO_KEPT] = 'Vårt fyrverkeri skal oppbevares'
nb[keys.TEXT_PYRO_APPROVED_CONTAINER] = 'I godkjent container'
nb[keys.TEXT_PYRO_OWN_WAREHOUSE] = 'I eget lager'
nb[keys.TEXT_PYRO_SALE_6] =
    'Dersom fyrverkeriet oppbevares i GODKJENT CONTAINER kreves en skisse som tydelig viser plassering av denne og at sikkerhetsavstander er i samsvar med regelverkets krav. Se eksempel her.'
nb[keys.TEXT_PYRO_SALE_7] =
    'Dersom fyrverkeriet oppbevares i EGET LAGER i tilknytning til handelslokalet, kreves en brannteknisk skisse som viser plassering av lager, evakuering og at sikkerhetsavstander er i henhold til regelverkets krav.'
nb[keys.TEXT_SKETCH_OF_CONTAINER_LOCATION] = 'Skisse over plassering av container'
nb[keys.TEXT_PYRO_COMPANY_TEST] = 'Firmaatest fra Foretaksregisteret'
nb[keys.TEXT_HEREBY_CONFIRMED] = 'Det bekreftes herved at virksomheten har'
nb[keys.TEXT_DATE] = 'Dato'
nb[keys.TEXT_LAST_INTERNAL_CONTROL_DATE] = 'Dato for siste gjennomgang av virksomhetens internkontroll'
nb[keys.TEXT_PYRO_SALE_8] =
    '1. Sørget for at de lover og forskrifter i helse-, miljø- og sikkerhetslovgivningen som gjelder for virksomheten er tilgjengelig, og har oversikt over de krav som er av særlig viktighet for virksomheten.'
nb[keys.TEXT_PYRO_SALE_9] =
    '2. Sørget for at arbeidstakerne har tilstrekkelig kunnskaper og ferdigheter i det systematiske helse-, miljø- og sikkerhetsarbeidet, herunder informasjon om endringer.'
nb[keys.TEXT_PYRO_SALE_10] = '3. Sørget for at arbeidstakerne medvirker slik at samlet kunnskap og erfaring utnyttes.'
nb[keys.TEXT_PYRO_SALE_11] = '4. Fastsatt mål for helse, miljø og sikkerhet '
nb[keys.TEXT_PYRO_SALE_12] =
    '5. Oversikt over virksomhetens organisasjon, herunder hvordan ansvar, oppgaver og myndighet for arbeidet med helse, miljø og sikkerhet er fordelt '
nb[keys.TEXT_PYRO_SALE_13] =
    '6. Kartlagt farer og problemer og på denne bakgrunn vurdert risiko, samt utarbeidet tilhørende planer og tiltak for å redusere risikoforholdene'
nb[keys.TEXT_PYRO_SALE_14] =
    '7. Iverksatt rutiner for å avdekke, rette opp og forebygge overtredelser av krav fastsatt i eller i medhold av helse-, miljø- og sikkerhets- lovgivningen.'
nb[keys.TEXT_PYRO_SALE_15] =
    '8. Foretatt systematisk overvåkning og gjennomgang av internkontrollen for å sikre at den fungerer som forutsatt'

nb[keys.TEXT_WE_WILL_CONTACT_YOU] = 'Vi tar kontakt for å planlegge kontrollen'
nb[keys.TEXT_WE_WILL_CONTACT_YOU_INSTALLATION] = 'Du vil bli kontaktet når søknaden er ferdig behandlet'

nb[keys.TEXT_APPLICANT] = 'Søker'
nb[keys.TEXT_CONSTRUCTION_SITE] = 'Byggeplass'
nb[keys.TEXT_APPLICATION_FOR_CHIMNEY_CONSTRUCTION] = 'Søknad om oppføring av skorstein'
nb[keys.TEXT_LIABILITY_DOCUMENTATION] = 'Legg ved dokumentasjon av ansvarsrett'

nb[keys.TEXT_CASE_NUMBER] = 'Saksnummer'
nb[keys.TEXT_HAVE_CASE_NUMBER] = 'Har saksnummer?'
nb[keys.TEXT_FIREPLACE_ALREADY_REPROTED] = 'Tidligere meldt inn ildsted:'
nb[keys.TEXT_INSTALLED_FIREPLACE] = 'hvilket ildsted er installert'
nb[keys.TEXT_OTHER_FIREPLACE_UNCERTAIN] = 'Annet ildsted'
nb[keys.TEXT_CLEAN_BURNING] = 'Rentbrennende'
nb[keys.TEXT_NON_CLEAN_BURNING] = 'Ikke rentbrennende'

nb[keys.TEXT_LOGIN_WITH_ID_PORTEN] = 'Logg inn med ID-porten'
nb[keys.TEXT_AUTHENTICATION_REQUIRED] = 'Godkjenning kreves'
nb[keys.TEXT_PERSONAL_NR] = 'Personnummer'
nb[keys.TEXT_USER_NOT_FOUND] = 'Bruker ikke funnet'
nb[keys.TEXT_LOGGED_OUT] = 'Du er nå logget ut av ID-porten'

nb[keys.TEXT_JOB_APPLIES] = 'Jobben gjelder'
nb[keys.TEXT_REHABILITATION_OF_EXISTING_CHIMNEY] = 'Rehabilitering av eksisterende skorstein'
nb[keys.TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY] = 'Bygging av en ny skorstein'

nb[keys.TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON] = 'Søknad om dispensasjon for fjerning av oljetank ble godkjent'
nb[keys.TEXT_SEARCH_BY_ADDRESS_GNR_BNR] = 'Søk etter adresse eller gnr/bnr'

nb[keys.TEXT_ROOF_TILE] = 'Teglstein'
nb[keys.TEXT_ELEMENT] = 'Element'
nb[keys.TEXT_STEEL] = 'Stål'

nb[keys.TEXT_CHIMNEY_BEFORE_REHAB] = 'Skorstein før rehabilitering'
nb[keys.TEXT_CHIMNEY_AFTER_REHAB] = 'Skorstein etter rehabilitering'

nb[keys.TEXT_NUMBER_OF_RACES] = 'Antall løp'
nb[keys.TEXT_SINGLE_RACES] = 'Enkeltløp'
nb[keys.TEXT_DOUBLE_RACES] = 'Dobbeltløp'

nb[keys.TEXT_CROSS_SECTION] = 'Tverrsnitt'
nb[keys.TEXT_CROSS_SECTION_CM] = 'Tverrsnitt (cm)'
nb[keys.TEXT_OLD_CROSS_SECTION_CM] = 'Gammelt tverrsnitt (cm)'
nb[keys.TEXT_NEW_CROSS_SECTION_CM] = 'Nytt tverrsnitt (cm)'
nb[keys.TEXT_NUMBER_OF_RUNNING_METERS] = 'Antall løpemeter'
nb[keys.TEXT_BY_THE_METER] = 'Løpemeter'
nb[keys.TEXT_PLACEMENT] = 'Plassering'
nb[keys.TEXT_FOR_EXAMPLE_RIGHT_LEFT_NORTH_SOUTH] = 'F.eks Høyre, venstre, nord, sør'
nb[keys.TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING] = 'Teknisk godkjennings nummer /CE merking'
nb[keys.TEXT_APPROVAL] = 'Godkjenning'
nb[keys.TEXT_TERMS_OF_INSTALLATION] = 'Oppstillingsvilkår'
nb[keys.TEXT_CM_10_DISTANCE] = '10 cm avstand'
nb[keys.TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS] = 'Direkte gjennom etasjeskiller'
nb[keys.TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL] = 'Direkte mot brennbar vegg'

nb[keys.TEXT_HOME_OWNER_CONTACT_PERSON] = 'Velg hjemmelshaver som er kontaktperson'
nb[keys.TEXT_WHICH_CHIMNEY_REHAB] = 'Hvilken skorstein skal rehabiliteres?'
nb[keys.TEXT_NEWLY_CONSTRUCTED_CHIMNEY] = 'Skorstein ved nyoppføring'

nb[keys.TEXT_TARGETED_SKETCH] = 'Målsatt skisse'
nb[keys.TEXT_MOUNTING_DESCRIPTION] = 'Monteringsbeskrivelse'
nb[keys.TEXT_NOTIFICATION_TO_NEIGHBORS] = 'Nabovarsel'
nb[keys.TEXT_CENTRAL_APPROVAL] = 'Sentral godkjening eller søknad om lokal godkjenning.'
nb[keys.TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED] = 'Ved søknad om lokal godkjenning må følgende lastes opp:'
nb[keys.TEXT_CENTRAL_APPROVAL_UPLOADS] = 'Søknad om ansvarsrett, fagbrev/svennebret, annen relevant komptanse'
nb[keys.TEXT_IS_APPLICANT_OWNER] = 'Innsender  er eier av oljetank'

nb[AuditType.FIRE_OWNER] = 'Eiertilsyn'
nb[AuditType.FIRE_USER] = 'Brukertilsyn'
nb[AuditType.MAPPING] = 'Kartlegging'
nb[AuditStatus.ACTIVE] = 'Opprettet'
nb[AuditStatus.APPROVED] = 'Avsluttet'
nb[AuditType.EL_REVISION] = 'Revisjon av elvirksomhet'
nb[AuditType.REVISION] = 'Revisjon'
nb[AuditType.OTHER_CASE] = 'Generell sak'
nb[keys.TEXT_STEP] = 'Steg'

nb[keys.ERROR_ONLY_ONE_APPLICATION_PER_HOUSING_UNIT] = 'Det er allerede levert søknad for denne bruksenheten'
nb[keys.ERROR_NO_HOUSING_UNITS] = 'Du har ingen bruksenhet som er gyldig for denne søknaden'
nb[keys.TEXT_FUEL_TYPE_SOLID] = 'Fast'
nb[keys.TEXT_FUEL_TYPE_COMBO] = 'Kombinasjon'
nb[keys.TEXT_FUEL_TYPE_BIO_OIL] = 'Bio-olje'

nb[keys.TEXT_CONCERN_CASE] = 'Bekymringsmelding'
nb[keys.TEXT_SENDER] = 'Innsender'
nb[keys.TEXT_SEND_AS_ANONYMOUS] = 'Ønsker du å sende inn meldingen anonymt?'
nb[keys.TEXT_FILES] = 'Legg til fil'
nb[keys.TEXT_CONCERN_DESCRIPTION] = 'Årsak til bekymring'

//#endregion FIRE
