import React from 'react'
import { Grid, makeStyles, Theme } from '@material-ui/core'
import classes from './layout-default.module.css'
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../app/app-store";
import {actionSetTenantSelectorVisible, actionStoreTenantData, actionStoreTenantId} from "../../app/app-actions";
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme: Theme) => ({
    childrenWrapper: {
        maxWidth: '960px',
        width: '100%',
        padding: '30px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
}))

type LayoutComponentProps = {
    logo: React.ReactElement
    rightElement?: React.ReactElement
    maxWidth?: string
}

const LayoutDefault: React.FC<LayoutComponentProps> = (props) => {
    const { logo, rightElement, maxWidth = '960px', children } = props
    const styles = useStyles()
    const dispatch = useDispatch()
    const stateTenantId = useSelector((state: AppState) => state.appData.tenantId)
    const sessionKey = useSelector((state: AppState) => state.appData.sessionKey)
    return (
        <>
            <Grid container justifyContent="center" className={classes.headerContainer}>
                <Grid item style={{ maxWidth }} className={classes.headerContent}>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item className={classes.headerLogo}>
                            {logo}
                        </Grid>
                        {rightElement && (
                            <Grid item>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    {stateTenantId && !sessionKey && <div style={{marginRight:16}}>
                                    <Link

                                        component="button"
                                        variant="body2"
                                        underline={'always'}
                                        onClick={() => {
                                            dispatch(actionSetTenantSelectorVisible(true))
                                        }}>
                                        Endre kommune
                                    </Link>
                                    </div>}
                                {rightElement}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item className={styles.childrenWrapper}>
                    {children}
                </Grid>
            </Grid>
        </>
    )
}

export default LayoutDefault
